<template>
    <div class="flex items-center">
        <span class="w-2 h-2 rounded-full block mr-2" :style="`background-color: ${markerColor}`"></span>
        <span class="capitalize">{{status}}</span>
    </div>
</template>

<script>
import {STATUS} from '@/components/auth/constants';

const markerColors = {
    [STATUS.ACTIVE]: '#BDE5CB',
    [STATUS.INACTIVE]: '#FFCCCC',
    [STATUS.INVITED]: '#F5EDC1',
    [STATUS.DELETED]: '#FFCCCC',
};

export default {
    name: 'StatusLabel',
    props: {
        /**
         * Status value. Supported values ACTIVE|INACTIVE|INVITED|DELETED
         */
        status: {
            type: String,
            default: '',
        }
    },
    computed: {
        markerColor() {
            return markerColors[this.status.toLowerCase()] || 'none';
        }
    },
};
</script>

