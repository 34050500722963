<template>
  <th
    v-if="isHeader"
    class="vuetable-th-component-status"
    v-html="title"
  />
  <td v-else class="vuetable-td-component-status">
    <StatusLabel :status="status" />
  </td>
</template>

<script>
  import VuetableFieldMixin from 'vue3-vuetable/src/components/VuetableFieldMixin.vue';
  import StatusLabel from "@/components/auth/StatusLabel";

  export default {
    name: 'StatusField',
    mixins: [VuetableFieldMixin],
    components: {
      StatusLabel,
    },
    computed: {
      source() {
        return this.rowField?.switch?.source;
      },
      status() {
        const getStatus =  this.rowField?.switch?.getStatus;
        return getStatus ? getStatus(this.rowData) : this.rowData[this.source];
      },
    },
  };
</script>
