<template>
  <List
    title="Admins"
    resource="customerAdmins"
    :basePath="basePath"
    :fields="fields"
    :request-params="{customerId: customerId, deletedOnly: true}"
    ref="list"
    track-by="customerUserId"
    on-row-click="details"
  />
</template>

<script>
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";
  import ModalNavigation from '@/mixins/ModalNavigation';
  import NotifyMixin from "@/mixins/NotifyMixin";
  import List from '@/components/auth/list/List';
  import UserField from '@/components/auth/list/fields/UserField';
  import DateTimeField from '@/components/auth/list/fields/DateTimeField';
  import StatusField from "@/components/auth/list/fields/StatusField";

  export default {
    name: 'DeletedAdminsList',
    components: {
      List,
    },
    mixins: [ModalNavigation, NotifyMixin, ConfirmationMixin],
    computed: {
      basePath: function () {
        return this.$route.path;
      },
      customerId: function () {
        return this.$route.params.customerId;
      }
    },
    data() {
      return {
        fields: [
          {
            name: UserField,
            title: 'Name',
            sortField: 'fullName',
            switch: {
              getName: rowData => rowData.fullName
            }
          },
          {
            name: 'role.name',
            sortField: 'role',
          },
          {
            name: StatusField,
            sortField: 'status',
            title: 'Status',
            switch: {
              source: 'status'
            }
          },
          {
            name: DateTimeField,
            sortField: 'createdAt',
            title: 'Registered',
            titleClass: 'text-center',
            dataClass: 'text-center',
            switch: {
              source: 'createdAt'
            }
          },
          {
            name: DateTimeField,
            sortField: 'lastLogin',
            title: 'Last login',
            titleClass: 'text-center',
            dataClass: 'text-center',
            switch: {
              source: 'lastLogin'
            }
          }
        ]
      };
    }
  };
</script>
